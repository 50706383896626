import { stegaClean } from "@sanity/client/stega";
import { defineConfig } from "cva";
import { type PortableTextTextBlock } from "sanity";
import { extendTailwindMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";

import { type SanityReferenceStrict } from "@/sanity/types";
import tailwindConfigFile from "@/tailwind.config";

export { stegaClean };

/**
 * Returns *true* if the current environment is a production environment.
 */
export const isProduction = process.env.VERCEL_ENV === "production";

/**
 * Returns *true* if the current environment is a staging environment.
 */
export const isStaging = process.env.VERCEL_ENV === "preview";

/**
 * Returns *true* if the current environment is a development environment.
 */
export const isDevelopment = isProduction === false && isStaging === false;

/**
 * A type-safe representation of the project's Tailwind configuration.
 */
export const tailwindConfig = resolveConfig(tailwindConfigFile);

/**
 * An extended version of Tailwind Merge's default configuration.
 */
export const tailwindMergeConfig = extendTailwindMerge({
  extend: {
    theme: {
      spacing: objectKeys(tailwindConfig.theme.spacing),
    },
  },
});

/**
 * Utilities for Class Variance Authority with Tailwind Merge support included.
 */
export const { cva, cx, compose } = defineConfig({
  hooks: {
    onComplete: (className) => tailwindMergeConfig(className),
  },
});

/**
 * Checks for undefined, null, or empty values. Supports strings, booleans, arrays, and objects.
 */
export function isDefinedAndNotEmpty<T>(value: T | undefined | null): value is T {
  // Check for undefined and null values.
  if (typeof value === "undefined" || value === null || value === false) return false;

  // Check for empty strings and arrays.
  if (typeof value === "string" || Array.isArray(value)) return value.length > 0;

  // Check for empty objects.
  if (typeof value === "object") return objectKeys(value).length > 0;

  return true;
}

/**
 * Checks whether or not a given Sanity reference has been expanded in GROQ.
 */
export function isExpandedReference<T>(
  reference: (T | SanityReferenceStrict | undefined) | (T | SanityReferenceStrict | undefined)[],
): reference is T | T[] {
  // Type guard to check if the reference contains a key of "_ref".
  function hasRefKey(object: unknown): object is { _ref: string } {
    return (object as { _ref: string })._ref !== undefined;
  }

  // Convert to an array for easier manipulation.
  const references = Array.isArray(reference) ? reference : [reference];

  // Check if the reference has a "_type" of "reference".
  for (const reference of references) {
    if (isDefinedAndNotEmpty(reference) && hasRefKey(reference)) {
      const message = `The reference "${reference._ref}" is of type "${reference._type}". Did you forget to expand the reference in the GROQ query? https://www.sanity.io/docs/how-queries-work#8ca3cefc3a31`;

      console.warn(message);
    }
  }

  return true;
}

/**
 * Checks whether or not a given expression is callable (of type function).
 */
export function isCallable<T extends (...args: unknown[]) => ReturnType<T>>(
  value: unknown,
): value is T {
  // Check if the value is a function.
  return typeof value === "function";
}

/**
 * Checks whether or not two given arrays are the same.
 */
export function areArraysEqual(arrayA: unknown[] | undefined, arrayB: unknown[] | undefined) {
  if (!isDefinedAndNotEmpty(arrayA) || !isDefinedAndNotEmpty(arrayB)) return false;
  if (arrayA.length !== arrayB.length) return false;

  return arrayA.every((item, index) => item === arrayB[index]);
}

/**
 * Type-safe alternative for `Object.entries()`.
 */
export function objectEntries<T extends object>(object: T): [keyof T, T[keyof T]][] {
  return Object.entries(object) as [keyof T, T[keyof T]][];
}

/**
 * Type-safe alternative for `Object.fromEntries()`.
 */
export function objectFromEntries<T extends object>(entries: [keyof T, T[keyof T]][]): T {
  return Object.fromEntries(entries) as T;
}

/**
 * Type-safe alternative for `Object.keys()`.
 */
export function objectKeys<T extends object>(object: T): (keyof T)[] {
  return Object.keys(object) as (keyof T)[];
}

/**
 * Type-safe alternative for `Object.values()`.
 */
export function objectValues<T extends object>(object: T): T[keyof T][] {
  return Object.values(object) as T[keyof T][];
}

/**
 * Converts a string into kebab case - suitable for slugs or URLs.
 */
export function toKebabCase(value: string | undefined) {
  if (!isDefinedAndNotEmpty(value)) return undefined;

  return value
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

/**
 * Extracts the text from the value of a Portable Text block.
 */
export function textFromPortableText(portableText: PortableTextTextBlock[] | undefined) {
  if (!isDefinedAndNotEmpty(portableText)) return undefined;

  return portableText.flatMap((block) => block.children.map((child) => child.text)).join("\n\n");
}

/**
 * Creates a timed throttle. Useful for testing network requests within React Suspense.
 */
export function delaySuspenseBoundary(milliseconds: number) {
  if (!isDevelopment) return undefined;

  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Converts a REM value to pixels (px).
 */
export function remToPx(rem: number) {
  const base = 16;

  return rem * base;
}
