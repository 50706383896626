import plugin from "tailwindcss/plugin";

export const hocus = plugin(({ addVariant }) => {
  addVariant("hocus", ["@media (hover: hover) { &:hover }", "&:focus", "&:focus-within"]);
  addVariant("group-hocus", [
    ":merge(.group):hover &",
    ":merge(.group):focus &",
    ":merge(.group):focus-within &",
  ]);
});
