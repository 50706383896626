export const studioUrl = "/studio";

export const draftApiUrl = "/api/draft";

export const disableDraftApiUrl = "/api/disable-draft";

export const projectId = assertValue(
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  "The `NEXT_PUBLIC_SANITY_PROJECT_ID` environment variable is required.",
);

export const dataset = assertValue(
  process.env.NEXT_PUBLIC_SANITY_DATASET,
  "The `NEXT_PUBLIC_SANITY_DATASET` environment variable is required.",
);

/** @see https://www.sanity.io/docs/api-versioning */
export const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION || "2023-12-01";

export const revalidateSecret = process.env.SANITY_REVALIDATE_SECRET;

/**
 * Checks to see if a given value exists before throwing an error.
 */
function assertValue<T>(value: T | undefined, errorMessage: string) {
  if (value === undefined) throw new Error(errorMessage);

  return value;
}
