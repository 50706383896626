import containerQueries from "@tailwindcss/container-queries";
import { type Config } from "tailwindcss/types/config";

import { hocus } from "./plugins/tailwind.hocus";

const sidebarMaximumWidth = 340;
const sidebarMinimumWidth = 52;
const tabBarHeight = 64;

const config = {
  content: ["./app/**/*.{js,ts,jsx,tsx,mdx}", "./components/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      screens: {
        container: "1440px",
      },
      containers: {
        "max-container": "1440px",
      },
      spacing: {
        base: "1rem",
        md: "2rem",
        lg: "3rem",
        "sidebar-min-w": `${sidebarMinimumWidth}px`,
        "sidebar-max-w": `${sidebarMaximumWidth}px`,
        "tab-bar-h": `${tabBarHeight}px`,
      },
      colors: {
        brand: {
          "burnt-orange": "#f2693c",
          fuchsia: "#8415ab",
          gold: "#dca124",
          gray: {
            400: "#f6f7f9",
            500: "#dbdbdb",
            600: "#6A6F7B",
            800: "#4b5052",
            900: "#1d1f20",
          },
          "leaf-green": "#008e03",
          "olive-green": "#adbf6a",
          pink: "#b755a3",
          red: {
            200: "#ffdedc",
            DEFAULT: "#b53c36",
            600: "#8d2f2a",
          },
          "sky-blue": "#5ab1f5",
          stone: "#b28a3c",
          turquoise: "#3f9c9c",
          ultramarine: "#0052b3",
          violet: "#8085ff",
        },
      },
    },
    fontFamily: {
      sans: ["var(--font-whitney)", "Helvetica", "sans-serif"],
    },
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
  plugins: [containerQueries, hocus],
} satisfies Config;

export default config;
