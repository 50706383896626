import createImageUrlBuilder from "@sanity/image-url";
import { groq } from "next-sanity";

import { type LinkProps } from "@/components/link";
import {
  isDefinedAndNotEmpty,
  isExpandedReference,
  objectKeys,
  tailwindConfig,
  toKebabCase,
} from "@/lib/utils";
import { dataset, projectId } from "@/sanity/lib/api";
import { iconSets, paths } from "@/sanity/lib/data";
import {
  type SanityExternalLink,
  type SanityInternalLink,
  type SanityLink,
} from "@/sanity/schemas/types/link";
import {
  type SanityDocumentType,
  type SanityImage,
  type SanityRoutableDocumentType,
} from "@/sanity/types";
import { type NextSearchParamKey, type NextSearchParams } from "@/types";

type SanityUrlCommonProps = {
  base?: string;
  slug?: string;
  anchor?: string;
};

type SanityUrlForNonTopicTypeProps = SanityUrlCommonProps & {
  type: Exclude<SanityRoutableDocumentType, "topic"> | undefined;
  themeSlug?: string;
};

type SanityUrlForTopicTypeProps = SanityUrlCommonProps & {
  type: "topic" | undefined;
  themeSlug: string | undefined;
};

type SanityUrlProps = SanityUrlForNonTopicTypeProps | SanityUrlForTopicTypeProps;

const imageBuilder = createImageUrlBuilder({
  projectId: projectId || "",
  dataset: dataset || "",
});

/**
 * Checks whether or not a given link is an internal link (a Sanity reference).
 */
export function isInternalLink(link: SanityLink): link is SanityInternalLink {
  return link.type === "internalLink";
}

/**
 * Checks whether or not a given link is an external link.
 */
export function isExternalLink(link: SanityLink): link is SanityExternalLink {
  return link.type === "externalLink";
}

/**
 * Helper to resolve images from a Sanity reference.
 */
export function resolveSanityImage(source: SanityImage) {
  const width = +tailwindConfig.theme.screens.container.replace("px", "");
  const image = imageBuilder.image(source).maxWidth(width);

  return image;
}

/**
 * Helper to resolve links from a Sanity reference.
 */
export function resolveSanityLink(link: SanityLink) {
  const object = {
    url: "",
    label: "",
    target: "_self" as LinkProps["target"],
    scroll: true,
  };

  if (isInternalLink(link)) {
    const { reference, anchor, label } = link;

    if (isExpandedReference(reference)) {
      const slug = "slug" in reference ? reference.slug?.current : undefined;
      const themeSlug = "theme" in reference ? reference.theme?.slug?.current : undefined;

      object.url = resolveSanityUrl({ type: reference._type, slug, themeSlug, anchor });
      object.label = label || reference.title || "";
    }
  }

  if (isExternalLink(link)) {
    const { url, label } = link;

    object.url = url || "";
    object.label = label || "";
    object.target = "_blank";
  }

  return object;
}

/**
 * Helper to resolve URLs based on the provided Sanity document type and slug.
 */
export function resolveSanityUrl({ base, type, slug, themeSlug, anchor }: SanityUrlProps) {
  const path = type ? paths[type] : undefined;
  const anchorId = anchor ? `#${toKebabCase(anchor)}` : "";

  const segments = [base, path, ...(type === "topic" ? [themeSlug] : []), slug, anchorId];

  const url = segments.filter((segment) => isDefinedAndNotEmpty(segment)).join("/");

  if (!isDefinedAndNotEmpty(base)) return `/${url}`;

  return url;
}

/**
 * Helper to resolve Sanity GROQ queries from search parameters.
 */
export function resolveSanityQueries(
  type: SanityDocumentType,
  searchParams: NextSearchParams,
  narrowSearchParams?: NextSearchParamKey[],
) {
  const getQuery = (
    values: string[],
  ): Partial<Record<SanityDocumentType, Partial<Record<NextSearchParamKey, string>>>> => {
    return {
      event: {
        theme: groq`&& count((milestones[].topic->theme->slug.current)[@ in [${values.map((value) => `"${value}"`)}]]) > 0`,
        topic: groq`&& count((milestones[].topic->slug.current)[@ in [${values.map((value) => `"${value}"`)}]]) > 0`,
        year: groq`&& string::split(date, "-")[0] in [${values.map((value) => `"${value}"`)}]`,
        month: groq`&& string::split(date, "-")[1] in [${values.map((value) => `"${value}"`)}]`,
      },
      resource: {
        theme: groq`&& topic->theme->slug.current in [${values.map((value) => `"${value}"`)}]`,
        topic: groq`&& topic->slug.current in [${values.map((value) => `"${value}"`)}]`,
      },
    };
  };

  const requestedQueries = () => {
    const queries: string[] = [];

    if (isDefinedAndNotEmpty(searchParams)) {
      objectKeys(searchParams).map((key: NextSearchParamKey) => {
        if (isDefinedAndNotEmpty(narrowSearchParams) && !narrowSearchParams.includes(key))
          return undefined;

        const value = searchParams[key];
        if (!isDefinedAndNotEmpty(value)) return undefined;

        const parsedValue = String(value).split(",");
        const query = getQuery(parsedValue)?.[type]?.[key];

        if (isDefinedAndNotEmpty(query)) queries.push(query);
      });
    }

    return queries;
  };

  const queries = requestedQueries();

  const filters = (isDefinedAndNotEmpty(queries) && queries.join(" ")) || "";

  return { filters };
}

/**
 * Returns a given icon by its name from the icon set.
 */
export function getIcon(name: string | undefined) {
  if (!isDefinedAndNotEmpty(name)) return undefined;

  const icon = iconSets.flatMap((iconSet) => iconSet.icons).find((icon) => icon.name === name);

  return icon;
}
