import {
  IconBatteryEco,
  IconBuildingBank,
  IconBuildingCommunity,
  IconBuildingFactory,
  IconCalendarEvent,
  IconCategory,
  IconChartHistogram,
  IconFileDownload,
  IconFlagShare,
  IconHome,
  IconInfoCircle,
  IconPigMoney,
  IconPlaneDeparture,
  IconPlant,
  IconRainbow,
  IconRipple,
  IconRoute,
  IconSearch,
  IconSolarPanel,
  IconSunset2,
  IconTemperature,
  IconTransform,
  IconTrees,
  IconWallet,
  IconWorldPin,
  type TablerIcon,
} from "@tabler/icons-react";

import { tailwindConfig } from "@/lib/utils";
import image from "@/public/default-seo-image.png";
import { type SanityRoutableDocumentTypePaths } from "@/sanity/types";

export type Color = {
  title: string;
  value: string;
  dark: boolean;
};

export type IconSet = {
  title: string;
  icons: Icon[];
};

export type Icon = {
  name: string;
  icon: TablerIcon;
  title: string;
};

export const defaultMetadata = {
  title: "Climate Finance Reform Compass",
  suffix: "CFR Compass",
  description:
    "The Compass aims to facilitate consensus and coordinate action among public and private stakeholders on reforms to the international financial architecture needed to meet the global climate challenge. It identifies pragmatic goals and milestones to focus efforts on actions where change could happen.",
  image: image.src,
};

export const defaultTitle = "Untitled";

export const paths: SanityRoutableDocumentTypePaths = {
  home: "",
  themes: "themes",
  roadmap: "roadmap",
  resources: "resources",
  page: "",
  theme: "themes",
  topic: "themes",
  event: "roadmap",
};

export const colors: Color[] = [
  {
    title: "Burnt Orange",
    value: tailwindConfig.theme.colors.brand["burnt-orange"],
    dark: false,
  },
  {
    title: "Fuchsia",
    value: tailwindConfig.theme.colors.brand.fuchsia,
    dark: true,
  },
  {
    title: "Gold",
    value: tailwindConfig.theme.colors.brand.gold,
    dark: false,
  },
  {
    title: "Leaf Green",
    value: tailwindConfig.theme.colors.brand["leaf-green"],
    dark: true,
  },
  {
    title: "Olive Green",
    value: tailwindConfig.theme.colors.brand["olive-green"],
    dark: false,
  },
  {
    title: "Pink",
    value: tailwindConfig.theme.colors.brand.pink,
    dark: true,
  },
  {
    title: "Sky Blue",
    value: tailwindConfig.theme.colors.brand["sky-blue"],
    dark: false,
  },
  {
    title: "Stone",
    value: tailwindConfig.theme.colors.brand.stone,
    dark: true,
  },
  {
    title: "Turquoise",
    value: tailwindConfig.theme.colors.brand.turquoise,
    dark: true,
  },
  {
    title: "Ultramarine",
    value: tailwindConfig.theme.colors.brand.ultramarine,
    dark: true,
  },
  {
    title: "Violet",
    value: tailwindConfig.theme.colors.brand.violet,
    dark: false,
  },
] as const;

export const iconSets: IconSet[] = [
  {
    title: "Menu",
    icons: [
      {
        name: "search",
        icon: IconSearch,
        title: "Search",
      },
      {
        name: "home",
        icon: IconHome,
        title: "Home",
      },
      {
        name: "roadmap",
        icon: IconCalendarEvent,
        title: "Roadmap",
      },
      {
        name: "themes",
        icon: IconCategory,
        title: "Themes",
      },
      {
        name: "resources",
        icon: IconFileDownload,
        title: "Resources",
      },
      {
        name: "about",
        icon: IconInfoCircle,
        title: "About",
      },
      {
        name: "feedback",
        icon: IconFlagShare,
        title: "Feedback",
      },
    ],
  },
  {
    title: "Themes",
    icons: [
      {
        name: "transformation",
        icon: IconTransform,
        title: "Transformation",
      },
      {
        name: "piggyBank",
        icon: IconPigMoney,
        title: "Piggy Bank",
      },
      {
        name: "chart",
        icon: IconChartHistogram,
        title: "Chart",
      },
      {
        name: "trees",
        icon: IconTrees,
        title: "Trees",
      },
      {
        name: "bank",
        icon: IconBuildingBank,
        title: "Bank",
      },
      {
        name: "wallet",
        icon: IconWallet,
        title: "Wallet",
      },
      {
        name: "globe",
        icon: IconWorldPin,
        title: "Globe",
      },
      {
        name: "sunshine",
        icon: IconSunset2,
        title: "Sunshine",
      },
      {
        name: "route",
        icon: IconRoute,
        title: "Route",
      },
      {
        name: "urbanDevelopment",
        icon: IconBuildingCommunity,
        title: "Urban Development",
      },
      {
        name: "solarEnergy",
        icon: IconSolarPanel,
        title: "Solar Energy",
      },
      {
        name: "wave",
        icon: IconRipple,
        title: "Wave",
      },
      {
        name: "temperature",
        icon: IconTemperature,
        title: "Temperature",
      },
      {
        name: "greenEnergy",
        icon: IconBatteryEco,
        title: "Green Energy",
      },
      {
        name: "rainbow",
        icon: IconRainbow,
        title: "Rainbow",
      },
      {
        name: "industry",
        icon: IconBuildingFactory,
        title: "Industry",
      },
      {
        name: "transport",
        icon: IconPlaneDeparture,
        title: "Transport",
      },
      {
        name: "plants",
        icon: IconPlant,
        title: "Plants",
      },
    ],
  },
];
